export default [
  {
    header: "Services"
  },
  {
    icon: "RepeatIcon",
    title: "Airport transfer",
    route: "foravila-services-airport-transfer"
  },
  // {
  //   // icon: 'ShoppingBagIcon',
  //   title: 'Taxi',
  //   route: 'foravila-services-taxi',
  // },
  // {
  //   // icon: 'ShoppingBagIcon',
  //   title: 'Charters',
  //   route: 'foravila-services-charter',
  // },
  // {
  //   icon: "ShoppingBagIcon",
  //   title: "Supermarket",
  //   route: "foravila-services-food"
  // },
  {
    icon: "MapIcon",
    title: "Rent a car",
    route: "foravila-services-car-rental"
  }
];
